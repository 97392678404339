jQuery(document).ready(function($) {
  $('.btcs-tabs-wrapper .tab-links').on('click', function(e) {
    e.preventDefault();
    var tabID = $(this).attr('data-tab-id');
    console.log('tab id = ' + tabID);
    // Remove active state from matching slide and tab nav items
    $(this).siblings('.tab-links').removeClass('active');
    $('.btcs-single-slide-wrapper').removeClass('active');
    // Add active class to slide and tab nav matching this id
    $(".btcs-single-slide-wrapper[data-slide-id='" + tabID + "']").addClass('active');
    $(this).addClass('active');
  });
}); /* end of as page load scripts */